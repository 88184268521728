import { client } from 'src/api'
import { formatCents } from 'src/utils/formatCents'
import { Icon } from 'src/components/Icon'

export const getPlanData = () => client.get('/api/v3/plans?payment_provider=stripe')

export const getPlans = async (currentPeriodMonthCount = null) => {
  const { data: plans } = await getPlanData()
  const processedPlans = processPlanData(plans)

  const _plans = processedPlans.filter(
    ({ periodMonthCount }) => periodMonthCount !== currentPeriodMonthCount
  )

  return _plans.map((plan) => {
    return {
      ...plan,
      monthlyPlanCost: plans?.find(({ periodMonthCount }) => periodMonthCount === 1)
        ?.periodPriceCents,
    }
  })
}

export const DEFAULT_ANNUAL_PLAN = 'price_test_yearly_variant_c'
export const DEFAULT_MONTHLY_PLAN = 'price_test_monthly_variant_c'

export const processPlanData = (plans) => {
  const baseCost = plans?.filter(({ periodMonthCount }) => periodMonthCount === 1)?.[0]
    ?.periodPriceCents

  return plans.map((plan) => ({
    labels: labels[plan.periodMonthCount] || {},
    savings: baseCost
      ? (baseCost - plan.periodPriceCents / plan.periodMonthCount) * plan?.periodMonthCount
      : null,
    ...plan,
  }))
}

export const filterPlansByTreatment = (plans, treatment) =>
  plans?.filter(({ planIdentifier }) =>
    treatment !== 'off' && treatment
      ? planIdentifier.includes(treatment)
      : !planIdentifier.includes('variant')
  )

export const getPlanUpgradeOptions = (plans, currentPlan) => {
  if (currentPlan.includes('variant_a')) {
    return plans?.filter(({ planIdentifier }) => planIdentifier.includes('variant_a'))
  } else if (currentPlan.includes('variant_b')) {
    return plans?.filter(({ planIdentifier }) => planIdentifier.includes('variant_b'))
  } else if (currentPlan.includes('variant_c')) {
    return plans?.filter(({ planIdentifier }) => planIdentifier.includes('variant_c'))
  } else {
    return plans?.filter(({ planIdentifier }) => !planIdentifier.includes('variant'))
  }
}

export const getDefaultPlan = (plans) =>
  plans?.find(({ planIdentifier }) => planIdentifier.includes('yearly'))

const labels = {
  1: {
    term: 'Monthly',
    adjective: 'monthly',
    period: 'month',
    perksList: () => (
      <div>
        <p>$24.99 billed monthly AFTER trial ends</p>
        <ul>
          <li>Online streaming, through the app, desktop, and supported casting</li>
          <li>
            Instant access to 10,000+ on-demand classes (including training programs!) from 5-60
            minutes, and at all skill levels
          </li>
          <li>22 live classes every day with world-class instructors</li>
        </ul>
      </div>
    ),
  },
  3: {
    term: 'Quarterly',
    adjective: 'quarterly',
    period: 'quarter',
    giftCardValue: '$10',
    savings: (savings) => (
      <div>
        <strong>SAVE {formatCents(savings, 0)}</strong> PER QUARTER
      </div>
    ),
    perks: () => (
      <p>
        Receive exclusive access to extra-premium classes and special offers from brand partners.
        <br />
        BONUS: Includes a <strong>$10 Gift Card</strong> to The obé Shop.
      </p>
    ),
    shortenedPerks: () => (
      <p>
        Receive exclusive access to extra-premium classes and special offers from brand partners.
      </p>
    ),
    perksList: () => (
      <div>
        <ul>
          <li>All the perks of Monthly membership, plus:</li>
          <li>
            <strong>Special discounts and offers</strong> from brand partners.
          </li>
          <li>
            <strong>Exclusive access</strong> to premium <Styles.LevelUpBadge />
            <strong>Level Up Club</strong> classes (aka the most popular workouts on obé!)
          </li>
          <li>
            <strong>Get $10</strong> to spend at <br />
            <u>
              <a href='https://shopobefitness.com/' target='_blank' rel='noreferrer'>
                The obé Shop
              </a>
            </u>
          </li>
        </ul>
      </div>
    ),
  },
  12: {
    term: 'Annual',
    adjective: 'annually',
    period: 'year',
    savings: (savings) => (
      <div>
        <strong>SAVE {formatCents(savings, 0)}</strong> PER YEAR
      </div>
    ),
    perks: () => (
      <p>
        Receive exclusive access to extra-premium classes and special offers from brand partners.
      </p>
    ),
    shortenedPerks: () => (
      <p>
        Receive exclusive access to extra-premium classes and special offers from brand partners.
      </p>
    ),
    perksList: () => (
      <div>
        <p>$169.99 billed annually AFTER trial ends</p>
        <ul>
          <li>Everything included in monthly AND</li>
          <li>Special discounts and offers from brand partners</li>
          <li>Exclusive access to premium Level Up Club classes</li>
          <li style={{ color: 'var(--persianBlue)' }}>Save $125+ per year</li>
        </ul>
      </div>
    ),
  },
}

const Styles = {
  LevelUpBadge: styled(Icon.LevelUpFlag)`
    padding-top: 4px;
    width: 6%;
    height: fit-content;
    display: inline-block;
    padding-right: 3.5px;
  `,
}
