export const content = {
  messages: (isResubscribing) => {
    if (isResubscribing) {
      return {
        dek: 'You can cancel at any time. Available in the US and Canada.',
        button: 'Start Your Subscription',
      }
    } else {
      return {
        dek: 'You will not be charged until your free trial ends. Cancel anytime. Available in the US and Canada.',
        button: 'Start free trial',
      }
    }
  },
}
