import { Styles } from './styles'
import { useRouter } from 'src/hooks/useRouter'
import { useSignUpContext } from 'src/hooks/useSignUpContext'

/* eslint-disable no-useless-escape */
const promotionalPathnames = [
  '/',
  '/aa',
  '/samsung',
  '/showfields',
  '/student',
  '/test',
  '/uberone',
  '/users/sign_up.*',
  '/comeback',
]
/* eslint-enable no-useless-escape */

export const PromotionBanner = ({ isMember }) => {
  const { pathname, query } = useRouter()

  const shareSource = query.source?.toLowerCase?.()

  const isDeprecatedSharePath =
    pathname.match(/\/videos\/.+/) &&
    (shareSource === 'web' || shareSource === 'mobile') &&
    query.userId

  const isPromotionalPath = promotionalPathnames.reduce(
    (acc, path) => acc || new RegExp(`^${path}$`, 'i').test(pathname),
    false
  )

  return !isMember && (isPromotionalPath || isDeprecatedSharePath) ? <Banner /> : null
}

const Banner = () => {
  const { bannerText } = useSignUpContext()
  const bannerRef = React.useRef()

  React.useEffect(() => {
    const siteHeaderHeight = `calc(${
      bannerRef.current?.offsetHeight || 0
    }px + var(--site-header-initial-height))`

    document.documentElement.style.setProperty('--site-header-height', siteHeaderHeight)
  }, [bannerText])

  if (!bannerText) {
    return null
  }

  return (
    <Styles.Container ref={bannerRef}>
      <Styles.Content>{bannerText}</Styles.Content>
    </Styles.Container>
  )
}
