import { ClickableMarketingPromoBanner } from './PromotionBanner/ClickableMarketingPromoBanner'
import { Logo } from './Logo'
import { MarketingPromoBanner } from 'src/components/App/Header/PromotionBanner/MarketingPromoBanner'
import { PromotionBanner } from './PromotionBanner'
import { scrollBody } from 'src/utils/scrollBody'
import { useResponsive } from 'src/hooks/useResponsive'
import { useUser } from 'src/hooks/useUser'
import { useRouter } from 'src/hooks/useRouter'
import { RecommendationsBanner } from 'src/components/RecommendationsBanner'

const NarrowMenu = dynamic(() => import('./NarrowMenu').then((mod) => mod.NarrowMenu), {
  ssr: false,
})

const TourDialog = dynamic(
  () => import('src/components/TourDialog').then((mod) => mod.TourDialog),
  { ssr: false }
)

const WideMenu = dynamic(() => import('./WideMenu').then((mod) => mod.WideMenu), { ssr: false })

export const SiteHeader = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isTourOpen, setTourOpen] = React.useState(false)
  const [isClickableBannerPresent, setIsClickableBannerPresent] = React.useState(false)
  const { isMediumAndDown } = useResponsive()
  const { isMember } = useUser()
  const router = useRouter()

  const stickyClassesMenu =
    router?.pathname === '/videos' || router?.pathname === '/obe-collections'
  const showRecommendationsBanner = isMember && router?.pathname === '/videos'

  const toggle = () => {
    if (isMediumAndDown) {
      setIsOpen(!isOpen)
      scrollBody(isOpen)
      obe.analytics.track(obe.events.user_action, {
        action: `nav_drawer_${!isOpen ? 'open' : 'close'}`,
      })
    }
  }

  return (
    <React.Fragment>
      <Render.Anchor
        privateBannerPresent={isClickableBannerPresent}
        stickyClassesMenu={stickyClassesMenu}
        showRecommendationsBanner={showRecommendationsBanner}
        isMediumAndDown={isMediumAndDown}
        isMember={isMember}
      >
        {isMediumAndDown && <Render.Mask isOpen={isOpen} onClick={toggle} />}
        <Render.Container>
          <ClickableMarketingPromoBanner
            setIsClickableBannerPresent={setIsClickableBannerPresent}
          />
          <MarketingPromoBanner />
          <PromotionBanner isMember={isMember} />
          <Render.NavContainer>
            <Render.NavContent>
              <Logo to={isMember ? '/home' : '/'} />
              {React.createElement(isMediumAndDown ? NarrowMenu : WideMenu, {
                isOpen,
                toggle,
                stickyClassesMenu,
                recommendationsBannerVisible: showRecommendationsBanner,
                openTourHandler: () => {
                  setTourOpen(true)
                  obe.analytics.track(obe.events.tour.launched_tour, {
                    source: 'nav',
                  })
                },
              })}
            </Render.NavContent>
          </Render.NavContainer>
          {showRecommendationsBanner && (
            <Render.RecommendationsBanner privateBannerPresent={isClickableBannerPresent} />
          )}
        </Render.Container>
      </Render.Anchor>
      {isTourOpen && (
        <TourDialog
          isOpen={isTourOpen}
          onClose={() => setTourOpen(false)}
          tourSlug='product-tour'
        />
      )}
    </React.Fragment>
  )
}

const Render = {
  Anchor: styled.div`
    ${({
      privateBannerPresent,
      stickyClassesMenu,
      showRecommendationsBanner,
      isMediumAndDown,
      isMember,
    }) => {
      const operands = ['var(--site-header-height)']

      if (privateBannerPresent) operands.push(`${privateBannerPresent}px`)
      if (stickyClassesMenu && isMember && !isMediumAndDown)
        operands.push('var(--sub-header-height)')
      if (showRecommendationsBanner)
        isMediumAndDown
          ? operands.push('var(--recs-banner-mobile-height)')
          : operands.push('var(--recs-banner-height)')

      return css`
        height: calc(${operands.join(' + ')});
      `
    }}

    width: 100%;
  `,
  Mask: styled.div`
    background: rgba(0, 0, 0, 0.4);
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: visibility 0s, opacity 0.2s linear;
    z-index: 900;

    ${({ isOpen }) => css`
      opacity: ${isOpen ? 1 : 0};
      visibility: ${isOpen ? 'visible' : 'hidden'};
    `}
  `,
  Container: styled.div`
    height: var(--site-header-height);
    left: 0;
    position: fixed;
    top: 0;
    transition: all 0.2s ease-out;
    width: 100%;
    z-index: 1000;
  `,
  RainbowRule: styled.div`
    height: 2px;
    background: linear-gradient(
      90deg,
      var(--yellow) 0.7%,
      var(--mediumPink) 29.86%,
      var(--lightBlue) 52.12%,
      var(--lavender) 99.2%
    );
    transform: rotate(180deg);
  `,
  NavContainer: styled.div`
    align-items: center;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    height: var(--site-header-height);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    padding: 0 15px;
    position: relative;
  `,
  NavContent: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,
  RecommendationsBanner: styled(RecommendationsBanner)`
    position: fixed;
    left: 0;
    ${({ privateBannerPresent }) =>
      privateBannerPresent
        ? css`
            top: calc(${privateBannerPresent}px + var(--site-header-height));
          `
        : css`
            top: var(--site-header-height);
          `}

    width: 100%;
  `,
}
