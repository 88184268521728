import { Link } from 'src/components/Link'

export const Styles = {
  Container: styled.div`
    background: var(--persianBlue);
  `,
  ClickableContainer: styled(Link)`
    width: 100%;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  `,
  Content: styled.div`
    font-weight: normal;
    color: white;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.522px;
    margin: 0 auto;
    max-width: 750px;
    padding: 16px 32px;
    text-align: center;

    p {
      margin-bottom: 0;
    }
    p > a {
      color: white;
      text-decoration: underline;
    }
    p > a:hover {
      color: white;
    }
  `,
}
