import { copy } from 'src/styles'

type VariantsProps = {
  center?: boolean
  centraLight?: boolean
  light?: boolean
  centraBook?: boolean
  bold?: boolean
  compact?: boolean
  tight?: boolean
  uppercase?: boolean
}

const variants = styled.div<VariantsProps>`
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `}

  font-weight: normal;
  ${({ centraLight }) =>
    centraLight &&
    css`
      font-weight: 200;
    `}
  ${({ light, centraBook }) =>
    (light || centraBook) &&
    css`
      font-weight: 300;
    `}
  ${({ bold }) =>
    bold &&
    css`
      font-weight: 600;
    `}

  p {
    margin-bottom: 0;
  }
  p + p {
    margin-top: 1.5rem;
  }

  line-height: 1.5;
  ${({ compact }) =>
    compact &&
    css`
      line-height: 1.25;
      p + p {
        margin-top: 1.25rem;
      }
    `}
  ${({ tight }) =>
    tight &&
    css`
      line-height: 1;
      p + p {
        margin-top: 1rem;
      }
    `}

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

  a {
    color: var(--persianBlue);
    font-weight: 600;
  }

  b,
  strong {
    font-weight: 600;
  }

  h2,
  h3,
  h4 {
    font-weight: inherit;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
  }

  img {
    display: block;
    max-width: 100%;
    margin: 24px auto;
  }
`

export const RichText = {
  Medium: styled(variants)`
    ${copy.medium}
  `,
  Large: styled(variants)`
    ${copy.large}
  `,
  XLarge: styled(variants)`
    ${copy.xlarge}
  `,
}
