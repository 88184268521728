export const commonTreatmentProps = (treatment) => ({
  config: treatment?.config,
  isControlTreatment: treatment?.treatment === 'control',
  isOffTreatment: treatment?.treatment === 'off',
  isOnTreatment: treatment?.treatment === 'on',
  treatment: treatment?.treatment,
})

export const parseSplitTreatments = (treatments) =>
  Object.keys(treatments).reduce((newTreatments, treatmentKey) => {
    newTreatments[treatmentKey] = {
      treatment: treatments?.[treatmentKey]?.treatment,
      config: JSON.parse(treatments?.[treatmentKey]?.config),
    }

    return newTreatments
  }, {})

export const featureFlags = {
  marketingPageCopy: 'Marketing_Page_Copy',
  landingPageRedesign2023: 'Landing_Page_Redesign_2023',
}

export const variants = {
  marketingPageCopy: {
    variantA: 'variant_a_copy_women_health',
    variantB: 'variant_b_copy_longevity',
  },
  landingPageRedesign2023: {
    variantA: 'variant_a_lp',
    control: 'control_lp',
  },
}

export const getSplitNames = (context) => {
  switch (context) {
    case 'homepageLoggedOut':
      return [
        'New_Hero_Header',
        'Quiz_Before_Paywall',
        featureFlags.marketingPageCopy,
        featureFlags.landingPageRedesign2023,
      ]
    case 'homepageLoggedIn':
      return ['GEX_Checkin_Feature_Flag', 'PT_Checkin_Feature_Flag']
    case 'signUpPage':
      return ['Two-Step_Sign_Up_Flow', 'Quiz_Before_Paywall']
    case 'userProfile':
      return ['GEX_Checkin_Feature_Flag', 'PT_Checkin_Feature_Flag']
    default:
      return []
  }
}
