import { breakpoints, BreakpointName } from 'src/styles'
import capitalize from 'lodash.capitalize'
import { useWindowSize } from 'src/hooks/useWindowSize'

type IndicativeBreakpointName = `is${Capitalize<BreakpointName>}`

type ResponsiveResponse = {
  screenSize: string
} & {
  [key in IndicativeBreakpointName]: boolean
} & { [key in `is${Capitalize<BreakpointName>}AndUp`]: boolean } & {
  [key in `is${Capitalize<BreakpointName>}AndDown`]: boolean
}

export const useResponsive = (): ResponsiveResponse => {
  const { innerWidth } = useWindowSize()
  const flags: Partial<ResponsiveResponse> = {}

  breakpoints.map(({ name, min, max }) => {
    const key = `is${capitalize(name)}` as IndicativeBreakpointName
    const screenSize = innerWidth ? innerWidth >= min && innerWidth <= max : false

    if (screenSize) {
      flags['screenSize'] = name
    }

    flags[key] = screenSize
    flags[`${key}AndUp`] = innerWidth ? innerWidth >= min : false
    flags[`${key}AndDown`] = innerWidth ? innerWidth <= max : false
  })

  return flags as ResponsiveResponse
}
