import { ExceptionDispatcher } from 'src/components/App/ExceptionDispatcher'
import { DispatchPageView } from 'src/components/App/DispatchPageView'
import { ScrollToTop } from 'src/components/ScrollToTop'
import { SiteFooter } from 'src/components/App/Footer/SiteFooter'
import { SiteHeader } from 'src/components/App/Header/SiteHeader'
import { SignUpContextProvider } from 'src/hooks/useSignUpContext/SignUpContextProvider'
import { useUser } from 'src/hooks/useUser'

export const MarketingLayout = ({ children }) => {
  const userStore = useUser()

  return (
    <ExceptionDispatcher>
      <DispatchPageView>
        {!userStore.isLoading && (
          <ScrollToTop>
            <div style={{ minHeight: '100vh' }}>
              <SignUpContextProvider>
                <SiteHeader />
              </SignUpContextProvider>
              {children}
            </div>
            <SiteFooter />
          </ScrollToTop>
        )}
      </DispatchPageView>
    </ExceptionDispatcher>
  )
}
