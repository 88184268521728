import { breakpoint } from 'src/styles'
import Image from 'next/image'
import { Link } from 'src/components/Link'

export const Logo = ({ to }) => (
  <Styles.Container to={to} tracking={{ context: 'logo' }}>
    {React.createElement(Image, {
      alt: 'obé Fitness',
      layout: 'fill',
      priority: true,
      src: '/obe-logo-color.png',
    })}
  </Styles.Container>
)

const Styles = {
  Container: styled(Link)`
    display: block;
    height: 40px;
    position: relative;
    width: 96px;
    ${breakpoint.mediumAndDown} {
      height: 24px;
      width: 58px;
    }
  `,
}
