import { breakpoint } from 'src/styles'

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%;

  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      ${breakpoint.small} {
        max-width: 540px;
      }
      ${breakpoint.medium} {
        max-width: 720px;
      }
      ${breakpoint.large} {
        max-width: 960px;
      }
      ${breakpoint.xlarge} {
        max-width: 1140px;
      }
    `}

  ${({ page }) =>
    page &&
    css`
      margin-bottom: 3rem;
    `}

  ${({ tabs }) =>
    tabs &&
    css`
      ${breakpoint.mediumAndDown} {
        margin-top: 61px;
      }
    `}
`

export const FullContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;

  ${({ page }) =>
    page &&
    css`
      margin-bottom: 3rem;
    `}

  ${({ tabs }) =>
    tabs &&
    css`
      ${breakpoint.mediumAndDown} {
        margin-top: 61px;
      }
    `}
`

export const Content = styled.div`
  background: white;
  margin: 1rem auto;
  padding: 1.5rem 1rem 1rem 1rem;
  width: 100%;
  ${breakpoint.mediumAndUp} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  ${({ fullWidth = false }) =>
    !fullWidth &&
    css`
      ${breakpoint.largeAndUp} {
        width: 540px;
      }
    `}
  ${({ narrow = false }) =>
    narrow &&
    css`
      ${breakpoint.largeAndUp} {
        width: 540px;
      }
    `}
`
