export const formatCents = (cents, decimal = 2, showCurrency = true) =>
  cents || cents === 0 ? `${showCurrency ? '$' : ''}${(cents / 100).toFixed(decimal)}` : ''

export const formatSavings = (plans, monthlyPlanCost) => {
  const [monthlyPlan, annualPlan] = [
    plans.find(({ periodMonthCount }) => periodMonthCount === 1)?.periodPriceCents ||
      monthlyPlanCost,
    plans.find(({ periodMonthCount }) => periodMonthCount === 12)?.periodPriceCents,
  ]

  return (
    ((formatCents(monthlyPlan, 2, false) - (formatCents(annualPlan, 2, false) / 12).toFixed(2)) /
      formatCents(monthlyPlan, 2, false)) *
    100
  )
}
