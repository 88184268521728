import { useSplitAnonymousTreatmentsContext } from 'src/providers/Split/AnonymousTreatmentsProvider'
import { commonTreatmentProps } from '../utils'

export const useAnonymousTreatment = (splitName) => {
  const { anonymousTreatments } = useSplitAnonymousTreatmentsContext()
  const treatment = anonymousTreatments?.[splitName]
  const _commonTreatmentProps = React.useMemo(() => commonTreatmentProps(treatment), [treatment])

  return {
    ..._commonTreatmentProps,
  }
}
