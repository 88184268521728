import { Context } from 'src/components/App/Context'

export const useUiStore = () => {
  const state = React.useContext(Context)

  return state.uiStore
}

export const useUiState = () => {
  const state = React.useContext(Context)

  return {
    setBookmarkCollections: state.setBookmarkCollections,
    setBookmarkVideoDialog: state.setBookmarkVideoDialog,
    setClassFeedbackDialog: state.setClassFeedbackDialog,
    setPostClassDialog: state.setPostClassDialog,
    setFetchedBookmarks: state.setFetchedBookmarks,
    setFetchedFollowedCollections: state.setFetchedFollowedCollections,
    setGuestPassModal: state.setGuestPassModal,
    setDownloadAppModal: state.setDownloadAppModal,
    setHubWidget: state.setHubWidget,
    setModalType: state.setModalType,
    setProgramRatingModal: state.setProgramRatingModal,
    setProgramView: state.setProgramView,
    setMarketingModulesViewed: state.setMarketingModulesViewed,
    setUi: state.setUi,
    setVideoPlayerStatus: state.setVideoPlayerStatus,
    uiStore: state.uiStore,
  }
}
