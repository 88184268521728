import { getTextSection } from 'src/models/textSection'
import { Styles } from './styles'
import { useAsync } from 'react-async-hook'
import { useRouter } from 'src/hooks/useRouter'
import { useUser } from 'src/hooks/useUser'

export const ClickableMarketingPromoBanner = React.memo(({ setIsClickableBannerPresent }) => {
  const { isMember } = useUser()
  const {
    result: banner,
    loading,
    error,
  } = useAsync(getTextSection, ['clickable-promotion-banner'])
  const { asPath } = useRouter()
  const bannerRef = React.useRef()

  React.useEffect(() => {
    if (banner?.active) {
      setIsClickableBannerPresent(bannerRef?.current?.offsetHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banner?.active])

  const shouldPageShowBanner = ['/home', '/programs'].includes(asPath)

  if (loading || error || !banner.active || !shouldPageShowBanner || !isMember) return null

  return (
    <Styles.Container className='marketing-promo-banner' ref={bannerRef}>
      <Styles.Content dangerouslySetInnerHTML={{ __html: banner.items?.[0]?.blurb }} />
    </Styles.Container>
  )
})
