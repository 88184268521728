import { client } from 'src/api'
import { storage } from 'src/storage'

export const init = (isReady = false) => ({
  canSelectPlan: true,
  canSelfCancel: false,
  code: null,
  content: {
    bannerText: null,
    disclaimerCheckboxText: null,
    partnerBanner: null,
    signupBadge: null,
    signupDescription: null,
    signupHeadline: null,
    signupMessage: null,
    subscriptionDescription: null,
    subscriptionHeadline: null,
    subscriptionMessage: null,
  },
  isError: false,
  isReady,
  isValid: false,
  name: null,
  showTestimonials: true,
})

export const fetchStripeCoupon = async (code) => {
  const response = init(true)

  if (!code) {
    return response
  }

  try {
    const {
      data: {
        allowedPlans,
        amountOff,
        durationInMonths,
        metadata,
        name,
        valid,
        percentOff,
        percentOffPrecise,
      },
    } = await client.get(`/api/v3/coupon/${code}?payment_provider=stripe`)

    return {
      ...response,
      allowedPlans: allowedPlans || [],
      amountOff,
      isAmexPerk: metadata?.isAmexPerk === 'true',
      canSelectPlan: metadata?.canSelectPlan !== 'false',
      canSelfCancel: metadata?.canSelfCancel === 'true',
      code,
      content: {
        bannerText: metadata?.bannerText,
        canUserEnterCode: metadata?.canUserEnterCode,
        disclaimerCheckboxText: metadata?.disclaimerCheckboxText,
        partnerBanner: metadata?.partnerBanner,
        signupBadge: metadata?.signupBadge,
        signupDescription: metadata?.signupDescription,
        signupHeadline: metadata?.signupHeadline,
        signupMessage: metadata?.signupMessage,
        subscriptionDescription: metadata?.subscriptionDescription?.replace('27', '19'),
        subscriptionHeadline: metadata?.subscriptionHeadline,
        subscriptionMessage: metadata?.checkoutMessage,
      },
      durationInMonths,
      extendedTrialDays: metadata?.extendedTrialDays,
      forAnnualPlan: metadata?.forAnnualPlan === 'true',
      forQuarterlyPlan: metadata?.forQuarterlyPlan === 'true',
      isValid: valid,
      name,
      percentOff,
      percentOffPrecise,
      showTestimonials: metadata?.hideTestimonials !== 'true',
    }
  } catch (error) {
    return {
      ...response,
      isError: true,
    }
  }
}

export const useStripeCoupon = (code) => {
  const [coupon, setCoupon] = React.useState(init())

  React.useEffect(() => {
    const fetch = async () => {
      const response = await fetchStripeCoupon(code)
      setCoupon(response)
    }

    if (code) {
      fetch(code)
    } else {
      setCoupon(init(true))
    }
  }, [])

  return coupon
}

export const setStoredStripeCoupon = (code) =>
  storage.session.setItem(obe.storage_keys.promotion_code, btoa(code))

export const useStoredStripeCoupon = () => useStripeCoupon(getStoredCouponCode())

export const removeStoredCouponCode = () =>
  storage.session.removeItem(obe.storage_keys.promotion_code)

export const getStoredCouponCode = () => {
  try {
    let code = storage.session.getItem(obe.storage_keys.promotion_code)
    return code ? atob(code).toUpperCase() : null
  } catch (error) {
    removeStoredCouponCode()
    return null
  }
}

export const removeStoredGuestPassCode = () => {
  if (getStoredCouponCode() === obe.promo_codes.guest_pass) {
    removeStoredCouponCode()
    storage.session.removeItem('userId')
    storage.session.removeItem('referralName')
  }
}
