import { breakpoint as bps, Breakpoint } from 'src/styles'

interface CellProps extends Partial<Breakpoint<CellProps>> {
  width?: string
}

interface RowProps extends Partial<Breakpoint<RowProps>> {
  gutter?: number
  direction?: string
  spaceBetween?: boolean
  spaceAround?: boolean
  center?: boolean
}

interface GridProps extends Partial<Breakpoint<GridProps>> {
  gutter?: number
  cols?: number
}

const getResponsiveRules = <T extends Partial<Breakpoint<T>>>(style: (style: T) => string) =>
  React.memo(
    styled.div<T>`
      ${(props) =>
        (Object.keys(bps) as Array<keyof Breakpoint>).reduce(
          (styles, bp) => styles + (props[bp] ? `${bps[bp]} {${style(props[bp] as T)}}` : ''),
          style(props)
        )}
    `
  )

export const Flex = {
  Cell: getResponsiveRules<CellProps>(({ width = '100%' }) => {
    if (width === 'grow') {
      return `
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      `
    }
    if (width === 'shrink') {
      return `
        flex-basis: 0;
        flex-grow: 0;
        max-width: 100%;
      `
    }
    return `
      flex: 0 0 ${width};
      max-width: ${width};
      width: ${width};
    `
  }),
  Row: getResponsiveRules<RowProps>(
    ({
      gutter = 32,
      direction = 'row',
      spaceBetween = false,
      spaceAround = false,
      center = false,
    }) => `
    display: flex;
    flex-direction: ${direction};
    ${
      spaceBetween
        ? `
      justify-content: space-between;
    `
        : ''
    }
    ${
      spaceAround
        ? `
      justify-content: space-around;
    `
        : ''
    }
    ${
      center
        ? `
      justify-content: center;
    `
        : ''
    }
    ${
      direction === 'row'
        ? `
      flex-wrap: wrap;
      margin-left: -${gutter / 2}px;
      margin-right: -${gutter / 2}px;
      > * {
        padding: 0 ${gutter / 2}px;
      }
      > * + * {
        margin-top: 0;
      }
    `
        : `
      flex-wrap: nowrap;
      margin: 0;
      > * {
        padding: 0;
      }
      > * + * {
        margin-top: ${gutter}px;
      }
    `
    }
  `
  ),
  Grid: getResponsiveRules<GridProps>(
    ({ gutter = 16, cols = 1 }) => `
    display: flex;
    flex-wrap: wrap;
    margin: 0 -${gutter / 2}px -${gutter}px;
    > * {
      flex: 0 0 ${100 / cols}%;
      margin-bottom: ${gutter}px;
      max-width: ${100 / cols}%;
      padding: 0 ${gutter / 2}px;
    }
  `
  ),
}
