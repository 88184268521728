import { Flex } from 'src/components/Display/Flex'
import { Icon } from 'src/components/Icon'
import { Link } from 'src/components/Link'
import { Styles } from './styles'
import { useUser } from 'src/hooks/useUser'
import { useAnonymousTreatment } from 'src/providers/Split/hooks/useAnonymousTreatment'
import { featureFlags } from 'src/providers/Split/utils'

const linkTrackingParams = {
  context: 'footer',
}

export const SiteFooter = () => {
  const { isMember } = useUser()

  const { treatment: landingPageRedesign2023 } = useAnonymousTreatment(
    featureFlags.landingPageRedesign2023
  )

  return (
    <Styles.OuterContainer>
      {landingPageRedesign2023 !== 'variant_a_lp' && <Styles.GreyRule />}
      <Styles.Container>
        <Flex.Row spaceBetween gutter={0} smallAndDown={{ direction: 'column' }}>
          {landingPageRedesign2023 === 'variant_a_lp' && (
            <Styles.LogoContainer>
              <Styles.RedesignedLogo src='/obe-logo-color.png' height='auto' />
              <Styles.Rating />
            </Styles.LogoContainer>
          )}
          {landingPageRedesign2023 !== 'variant_a_lp' && (
            <Styles.Logo src='/obe-logo-color.png' width={720} height={312} />
          )}
          <Styles.Links
            smallAndDown={{ spaceBetween: true, gutter: 0 }}
            mediumAndUp={{ gutter: 48 }}
            largeAndUp={{ gutter: 96 }}
          >
            <div>
              {!isMember ? (
                <Link to='/about-us' tracking={linkTrackingParams}>
                  About Us
                </Link>
              ) : (
                <Link to='/getting-started' tracking={linkTrackingParams}>
                  Getting Started
                </Link>
              )}
              <Link to='/schedule' tracking={linkTrackingParams}>
                Live Schedule
              </Link>
              <Link to='/instructors' tracking={linkTrackingParams}>
                Instructors
              </Link>
              {isMember && (
                <Style.GuestPassLink
                  action={obe.events.navigation.navigated_to_guest_pass}
                  to='/guest-pass'
                  tracking={linkTrackingParams}
                >
                  <Style.OpenEnvelopeIcon />
                  <div>SEND GUEST PASS</div>
                </Style.GuestPassLink>
              )}
              <Link to='mailto:vip@obefitness.com'>Contact</Link>
            </div>
            <div>
              <Link to='/faq' tracking={linkTrackingParams}>
                FAQ
              </Link>
              <Link to='https://boards.greenhouse.io/obefitness' tracking={linkTrackingParams}>
                Careers
              </Link>
              <Link to='/privacy-policy' tracking={linkTrackingParams}>
                Privacy Policy
              </Link>
              <Link to='/terms-of-service' tracking={linkTrackingParams}>
                Terms of Service
              </Link>
              <Link to='https://work.obefitness.com/' tracking={linkTrackingParams}>
                Corporate wellness
              </Link>
            </div>
            <div>
              <Link to='https://shopobefitness.com/' tracking={linkTrackingParams}>
                Shop
              </Link>
              <Link
                to='https://shopobefitness.com/collections/equipment'
                tracking={linkTrackingParams}
              >
                Equipment
              </Link>
              <Link
                to='https://shopobefitness.com/collections/apparel-accessories'
                tracking={linkTrackingParams}
              >
                Apparel
              </Link>
              <Link
                to='https://shopobefitness.com/collections/accessories'
                tracking={linkTrackingParams}
              >
                Accessories
              </Link>
            </div>
          </Styles.Links>
          <Styles.AppStoreBadges>
            <Link
              to='https://apps.apple.com/app/apple-store/id1337083258?pt=119069445&ct=websitefooter&mt=8'
              tracking={linkTrackingParams}
            >
              <Styles.AppStore src='/app-store-badge.png' width={160} height={54} />
            </Link>
            <Link
              to='https://play.google.com/store/apps/details?id=com.studio.obe'
              tracking={linkTrackingParams}
            >
              <Styles.AppStore src='/google-play-badge.png' width={160} height={54} />
            </Link>
          </Styles.AppStoreBadges>
        </Flex.Row>
        <Flex.Row spaceBetween gutter={0} style={{ marginTop: 96 }}>
          <div style={{ lineHeight: '24px' }}>
            <span style={{ textTransform: 'none' }}>© obé Fitness</span>
          </div>
          <Flex.Row gutter={16}>
            <Link to='https://www.instagram.com/obe_fitness' tracking={linkTrackingParams}>
              <Styles.Icon src='/icon-instagram.png' width={23} height={24} />
            </Link>
            <Link
              to='https://www.facebook.com/groups/ourbodyelectricfam'
              tracking={linkTrackingParams}
            >
              <Styles.Icon src='/icon-facebook.png' />
            </Link>
          </Flex.Row>
        </Flex.Row>
      </Styles.Container>
    </Styles.OuterContainer>
  )
}

const Style = {
  ...Styles,
  GuestPassLink: styled(Link)`
    position: relative;

    div {
      padding-left: 15px;
    }
  `,
  GiftIcon: styled(Icon.YellowGift)`
    position: absolute;
    top: 6px;
    width: 12px;
  `,
  OpenEnvelopeIcon: styled(Icon.OpenEnvelope)`
    position: absolute;
    top: 6px;
    width: 12px;
  `,
}
