import { getTextSection } from 'src/models/textSection'
import { storage } from 'src/storage'
import { Styles } from './styles'
import { useAsync } from 'react-async-hook'
import { useRouter } from 'src/hooks/useRouter'

const activeRoutes = ['/', '/users/sign_up', '/pricing/tryobe']

export const MarketingPromoBanner = React.memo(() => {
  const {
    result: banner,
    loading,
    error,
  } = useAsync(getTextSection, ['marketing-page-promotion-banner'])
  const { query, asPath } = useRouter()

  if (
    loading ||
    error ||
    !banner.active ||
    Object.keys(query || {}).length !== 0 ||
    !activeRoutes.includes(asPath) ||
    Object.keys(storage.session.getItem('obe_utm') || {})?.length > 0
  )
    return null

  return (
    <Styles.Container>
      <Styles.Content dangerouslySetInnerHTML={{ __html: banner.items?.[0]?.blurb }} />
    </Styles.Container>
  )
})
