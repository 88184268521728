import { useUser } from 'src/hooks/useUser'

export const DispatchPageView = ({ children, event = '' }) => {
  const { hasFinishedGexQuiz, isPtActive } = useUser()

  const additionalProperties = {
    myScheduleState: isPtActive
      ? 'ptTrainingJourney'
      : hasFinishedGexQuiz
      ? 'gexTrainingJourney'
      : 'noTrainingJourney',
  }

  React.useEffect(() => {
    obe.analytics.page(event, additionalProperties)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}
