import { fetchStripeCoupon, getStoredCouponCode, init } from 'src/hooks/useStripeCoupon'
import { content } from './content'
import { Context } from './SignUpContextProvider'
import { getPlans } from 'src/models/plan'
import { storage } from 'src/storage'
import { useAsync } from 'react-async-hook'
import { useUser } from 'src/hooks/useUser'

export const useSignUpContext = () => {
  const { currentUser } = useUser()

  const { analytics, plan, plans, promoCode, setPlan, setPlans } = React.useContext(Context)

  let promotion = init(true)

  const { result: _plans } = useAsync(getPlans, [])
  const { result: _promotion } = useAsync(
    fetchStripeCoupon.bind(null, promoCode || getStoredCouponCode()),
    []
  )
  const selectedPlan = storage.session.getItem('selectedPlan')

  const _plan = _plans?.find(({ planIdentifier }) =>
    selectedPlan ? planIdentifier === selectedPlan : planIdentifier.includes('yearly')
  )

  React.useEffect(() => {
    if (_promotion?.isReady) {
      if (_promotion?.allowedPlans?.length === 1 && _plans?.length) {
        const allowedPlan = _plans?.filter(
          ({ periodMonthCount }) => periodMonthCount === _promotion.allowedPlans[0].periodMonthCount
        )
        setPlan(allowedPlan?.[0])
        setPlans(allowedPlan)
      } else {
        setPlan(_plan)
        setPlans(_plans)
      }
    }
  }, [_plans, _plan, _promotion, setPlans, setPlan])

  // if the plan allows this promotion, then set the promotion
  if (
    _promotion?.allowedPlans?.find(
      ({ periodMonthCount }) => periodMonthCount === plan?.periodMonthCount
    )
  ) {
    promotion = _promotion
  }

  const isResubscribing = currentUser?.account?.hasAnySubscriptions
  const messages = content.messages(isResubscribing)

  return {
    analytics,
    bannerText: _promotion?.content?.bannerText,
    isResubscribing,
    monthlyPlanCost: _plans?.find(({ periodMonthCount }) => periodMonthCount === 1)
      ?.periodPriceCents,
    messages,
    plan,
    plans,
    promotion,
    setPlan,
  }
}
