import { storage } from 'src/storage'

export const Context = React.createContext()

export class SignUpContextProvider extends React.Component {
  constructor(props) {
    super(props)

    this.setPlan = (plan) => {
      this.setState(() => ({
        plan,
      }))
    }

    this.setPlans = (plans) => {
      this.setState(() => ({
        plans,
      }))
    }

    let plan = storage.session.getItem('selectedPlan')

    this.state = {
      analytics: props.analytics,
      plan,
      plans: [],
      promoCode: props.promoCode,
      setPlan: this.setPlan,
      setPlans: this.setPlans,
    }
  }

  render() {
    return <Context.Provider value={this.state}>{this.props.children}</Context.Provider>
  }
}
