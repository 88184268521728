export const RatingStar = ({ className }: { className?: string }) => (
  <svg className={className} viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.581 1.6446C10.6795 1.39832 10.9258 1.2998 11.172 1.2998C11.4183 1.2998 11.6646 1.44757 11.7631 1.6446L14.4722 7.16132C14.5215 7.30909 14.6692 7.4076 14.8663 7.4076L20.9741 8.29422C21.2204 8.34348 21.4174 8.49125 21.5159 8.73753C21.6144 8.98381 21.5159 9.23009 21.3681 9.42712L16.9843 13.7124C16.8858 13.8109 16.8365 14.008 16.8365 14.1557L17.8217 20.2635C17.8709 20.5098 17.7724 20.7561 17.5754 20.9039C17.3784 21.0516 17.0828 21.0516 16.8858 20.9531L11.4183 18.0963C11.2706 17.9977 11.1228 17.9977 10.975 18.0963L5.50755 20.9531C5.31052 21.0516 5.01499 21.0516 4.81796 20.9039C4.62094 20.7561 4.52242 20.5098 4.57168 20.2635L5.60606 14.205C5.65532 14.0572 5.5568 13.8602 5.45829 13.7617L1.07447 9.47638C0.877443 9.27935 0.828185 9.03307 0.926697 8.78678C1.02521 8.5405 1.22224 8.39274 1.46852 8.34348L7.47781 7.45686C7.62558 7.45686 7.77335 7.30909 7.87186 7.21058L10.581 1.6446Z'
      fill='#622AFF'
    />
  </svg>
)
