type WindowSize = {
  innerHeight?: number
  innerWidth?: number
  outerHeight?: number
  outerWidth?: number
}

const getSize = () => ({
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
  outerHeight: window.outerHeight,
  outerWidth: window.outerWidth,
})

export const useWindowSize = (): WindowSize => {
  const initialSize = typeof window !== 'undefined' ? getSize() : {}

  const [windowSize, setWindowSize] = React.useState(initialSize)

  const onResize = () => {
    setWindowSize(getSize())
  }

  React.useEffect(() => {
    window.addEventListener('resize', onResize, { passive: true })

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return windowSize
}
