import { Container } from 'src/components/Display/Container'
import { Flex } from 'src/components/Display/Flex'
import { Image } from 'src/components/Display/Image'
import { breakpoint } from 'src/styles'
import { UserAction } from 'src/components/UserAction'
import { Rating } from './Rating'

export const Styles = {
  GreyRule: styled.div`
    background: var(--lightMediumGrey);
    height: 1px;
    transform: rotate(180deg);
  `,
  OuterContainer: styled.div`
    background-color: var(--white);
  `,
  Container: styled(Container)`
    font-size: 13px;
    letter-spacing: 0.015em;
    line-height: 1;
    padding-bottom: 48px;
    padding-top: 64px;
    text-transform: uppercase;
  `,
  LogoContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${breakpoint.smallAndDown} {
      flex-direction: row;
      justify-content: center;
    }
  `,
  Rating: styled(Rating)`
    margin-top: 20px;
    margin-left: 0;

    ${breakpoint.smallAndDown} {
      margin-top: 0;
      margin-left: 20px;
    }
  `,
  Logo: styled(Image)`
    width: 105px;
  `,
  RedesignedLogo: styled(Image)`
    width: 90px;
    border-radius: 11.565px;
    background: var(--neutrals100, #fff);
    padding: 28.9px 10.6px;
    box-shadow: 0px 1px 4.5px rgba(0, 0, 0, 0.25);
  `,
  AppStoreBadges: styled.div`
    > :not(:last-child) {
      > div {
        margin-bottom: 4px;
      }
    }
  `,
  AppStore: styled(Image)`
    width: 130px;
  `,
  Icon: styled(Image)`
    width: 24px;
  `,
  Links: styled(Flex.Row)`
    a {
      border-bottom: 8px solid transparent;
      display: block;
      line-height: 1px;
      padding: 14px 2px 0px 2px;
      width: fit-content;
    }
    a + a,
    a ~ div {
      margin-top: 16px;
    }
    a:focus {
      outline: none;
      border-color: var(--yellow);
    }
    a:hover {
      text-decoration: none;
      border-color: var(--yellow);
    }
  `,
  ActionTrigger: styled(UserAction)`
    outline: none;
    border-bottom: 8px solid transparent;
    width: fit-content;
    line-height: 0px;
    padding: 14px 2px 0px 2px;

    :focus {
      outline: none;
      border-color: var(--yellow);
    }
    :hover {
      text-decoration: none;
      border-color: var(--yellow);
    }
  `,
}
