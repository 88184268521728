import { Chevron } from '../Icon/Chevron'
import { breakpoint } from 'src/styles'
import { isMobile } from 'react-device-detect'

interface ArrowProps {
  direction: 'right' | 'left'
  className: 'string'
  id: 'string'
  isChevron: boolean
}

const ArrowIcon: React.FC<Partial<ArrowProps>> = ({ direction, className, id }) => (
  <Icon direction={direction} className={className} data-testid={id}>
    <svg width='100%' height='100%' viewBox='0 0 30 20' fill='none'>
      <path
        fill='var(--nearBlack)'
        d='M5.72205e-05 9.99952C5.72205e-05 9.30917 0.559702 8.74952 1.25006 8.74952L25.2126 8.74952L19.1157 2.65265C18.6273 2.16422 18.6273 1.37232 19.1157 0.883898C19.6041 0.395472 20.396 0.395471 20.8844 0.883898L28.5858 8.58531C29.3669 9.36636 29.3669 10.6327 28.5858 11.4137L20.8844 19.1151C20.396 19.6036 19.6041 19.6036 19.1157 19.1151C18.6273 18.6267 18.6273 17.8348 19.1157 17.3464L25.2126 11.2495L1.25006 11.2495C0.5597 11.2495 5.72205e-05 10.6899 5.72205e-05 9.99952Z'
      />
    </svg>
  </Icon>
)

const ChevronIcon: React.FC<Partial<ArrowProps>> = ({ direction, className, id }) => (
  <Icon direction={direction} className={className} data-testid={id} isChevron>
    <Chevron className={className} />
  </Icon>
)

const Icon = styled.div<{ direction: 'right' | 'left' | undefined; isChevron?: boolean }>`
  svg {
    display: block;
  }
  ${({ direction, isChevron }) =>
    direction === 'left' &&
    !isChevron &&
    css`
      transform: scaleX(-1);
    `}
  ${({ isChevron, direction }) =>
    isChevron &&
    css`
      position: absolute;
      bottom: 48px;

      left: ${direction === 'left' ? '-20px' : ''};
      right: ${direction === 'right' ? '-20px' : ''};

      ${breakpoint.smallAndDown} {
        bottom: 32px;
        left: ${direction === 'left' ? '-55px' : ''};
        right: ${direction === 'right' ? (isMobile ? '-55px' : '-40px') : ''};
      }

      @media (min-width: 700px) and (max-width: 1024px) {
        bottom: 32px !important;
        left: ${direction === 'left' ? '-55px' : ''} !important;
        right: ${direction === 'right' ? '-44px' : ''} !important;
      }

      @media (min-width: 1024px) and (max-width: 1200px) {
        left: ${direction === 'left' ? '-30px' : ''};
        right: ${direction === 'right' ? '-30px' : ''};
      }

      svg {
        transform: ${direction === 'right' ? 'rotate(275deg)' : 'rotate(90deg)'} !important;

        stroke: var(--neutrals800);
      }
    `}
`

const Left: React.FC<Partial<ArrowProps>> = ({ className, isChevron = false }) => {
  return isChevron ? (
    <ChevronIcon direction='left' className={className} id='left-arrow' />
  ) : (
    <ArrowIcon direction='left' className={className} id='left-arrow' />
  )
}

const Right: React.FC<Partial<ArrowProps>> = ({ className, isChevron = false }) => {
  return isChevron ? (
    <ChevronIcon direction='right' className={className} id='right-arrow' />
  ) : (
    <ArrowIcon direction='right' className={className} id='right-arrow' />
  )
}

export const Arrow = { Left, Right }
