import { getSplitNames, parseSplitTreatments } from './utils'
import { SplitClient, SplitTreatments } from '@splitsoftware/splitio-react'

export const SplitAnonymousTreatmentsContext = React.createContext({
  anonymousTreatments: {},
})

export const SplitAnonymousTreatmentsProvider = ({ attributes = {}, context, children }) => {
  const splitNames = React.useMemo(() => getSplitNames(context), [context])

  return (
    <SplitClient
      splitKey={obe.analytics.anonymousId(false) || obe.storage_keys.split_default_key}
      updateOnSdkTimedout={true}
      updateOnSdkReadyFromCache={false}
    >
      <SplitTreatments attributes={attributes} names={splitNames}>
        {({ isReady, isReadyFromCache, hasTimedout, treatments }) =>
          isReady || isReadyFromCache || hasTimedout ? (
            <TreatmentsProvider anonymousTreatments={treatments}>{children}</TreatmentsProvider>
          ) : null
        }
      </SplitTreatments>
    </SplitClient>
  )
}

const TreatmentsProvider = ({ anonymousTreatments = {}, children }) => {
  const [_anonymousTreatments, setAnonymousTreatments] = React.useState(anonymousTreatments)
  const [isReady, setIsReady] = React.useState(false)

  React.useEffect(() => {
    setIsReady(false)
    setAnonymousTreatments(parseSplitTreatments(anonymousTreatments))
    setIsReady(true)
  }, [anonymousTreatments])

  return isReady ? (
    <SplitAnonymousTreatmentsContext.Provider value={{ anonymousTreatments: _anonymousTreatments }}>
      {children}
    </SplitAnonymousTreatmentsContext.Provider>
  ) : null
}

export const useSplitAnonymousTreatmentsContext = () =>
  React.useContext(SplitAnonymousTreatmentsContext)
