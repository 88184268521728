import * as Sentry from '@sentry/nextjs'
import { breakpoint, mixin } from 'src/styles'
import { updateConditionalDisplay } from 'src/models/user'
import { Icon } from 'src/components/Icon'
import { Link } from 'src/components/Link'
import { useUser } from 'src/hooks/useUser'

export const RecommendationsBanner = ({ className }: { className?: string }) => {
  const { currentUser } = useUser()

  const displayNewBadge =
    currentUser?.account?.conditionalDisplayAttributes?.obeRecommendationsNew ?? true

  const updateNewBadgeVisibility = React.useCallback(async () => {
    try {
      await updateConditionalDisplay(currentUser?.id, { obeRecommendationsNew: false })
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [currentUser?.id])

  // Don't want to pass a promise into Link because we don't want to delay routing
  const onBannerClick = React.useCallback(() => {
    updateNewBadgeVisibility()
  }, [updateNewBadgeVisibility])

  return (
    <Styles.LinkContainer
      className={className}
      to='/recommended-classes'
      action={obe.events.recommendation_banner_pressed}
      onClick={onBannerClick}
    >
      <Styles.Title>
        {displayNewBadge && <Styles.NewBadge data-testid='new-badge' />}
        <span>obé Recommendations</span>
        <Styles.Sparkle />
      </Styles.Title>
      <Styles.Subtitle>See personalized classes just for you</Styles.Subtitle>
    </Styles.LinkContainer>
  )
}

const Styles = {
  LinkContainer: styled(Link)`
    ${mixin.flexCenterBoth}
    width: 100%;
    height: var(--recs-banner-height);
    background: linear-gradient(0deg, #ff95dc 0%, #dd01ad 87.04%);
    color: var(--white) !important;
    cursor: pointer;

    ${breakpoint.smallAndDown} {
      flex-direction: column;
      height: var(--recs-banner-mobile-height);
    }

    & > div {
      padding: 0px 20px;

      ${breakpoint.smallAndDown} {
        min-width: 300px;
      }
    }

    :hover {
      text-decoration: none;
    }
  `,
  Title: styled.div`
    ${mixin.flexCenterVertical}
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    ${breakpoint.smallAndDown} {
      font-size: 18px;
      margin-bottom: 8px;
    }
  `,
  Subtitle: styled.div`
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  `,
  Sparkle: styled(Icon.Sparkle)`
    margin-left: 8px;
  `,
  NewBadge: styled(Icon.NewBadge)`
    margin-right: 16px;

    ${breakpoint.smallAndDown} {
      margin-right: 8px;
    }
  `,
}
